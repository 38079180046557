<template>
  <router-layout>
    <div class="page">
      <div class="inner">
        <div class="title">我要租赁</div>
        <div class="form">
          <div class="input">
            <van-field v-model="name" clearable left-icon="manager-o" placeholder="姓名" />
          </div>
          <div class="input">
            <van-field
              v-model="phone"
              clearable
              :left-icon="phoneicon"
              placeholder="手机号码"
              type="number"
              maxlength="11"
            />
          </div>
          <div class="textarea">
            <van-field
              v-model="message"
              left-icon="comment-o"
              rows="2"
              autosize
              clearable
              type="textarea"
              maxlength="100"
              placeholder="写下你的留言,请用简短的文字说明您的要求,限100字以内"
              show-word-limit
            />
          </div>
          <div class="agreebox">
            <van-checkbox v-model="checked" icon-size=".4rem" checked-color="#F3B682">我已同意并阅读</van-checkbox>
            <span class="agree" @click="agree">用户协议</span>
          </div>
          <button class="submit" @click="Submit">提交信息</button>
          <p class="ys">您的私人信息,不会泄露给任何第三方</p>
        </div>
        <!-- -->
      </div>
      <!--弹窗-->
      <van-popup v-model="show" class="agreePop" closeable close-icon="close">
        <div class="tit">用户协议</div>
        <div class="body">
          <h2>隐私政策</h2>
          <p>本应用尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更有个性化的服务，本应用会按照本隐私权政策的规定使用和披露您的个人信息。但本应用将以高度的勤勉、审慎义务对待这些信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本应用不会将这些信息对外披露或向第三方提供。本应用会不时更新本隐私权政策。 您在同意本应用服务使用协议之时，即视为您已经同意本隐私权政策全部内容。本隐私权政策属于本应用服务使用协议不可分割的一部分。</p>
          <h2>信息使用</h2>
          <p>本应用不会向任何无关第三方提供、出售、出租、分享或交易您的个人信息，除非事先得到您的许可，或该第三方和本应用（含本应用关联公司）单独或共同为您提供服务，且在该服务结束后，其将被禁止访问包括其以前能够访问的所有这些资料。</p>
          <p>本应用亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何本应用平台用户如从事上述活动，一经发现，本应用有权立即终止与该用户的服务协议。</p>
          <p>为服务用户的目的，本应用可能通过使用您的个人信息，向您提供您感兴趣的信息，包括但不限于向您发出产品和服务信息，或者与本应用合作伙伴共享信息以便他们向您发送有关其产品和服务的信息（后者需要您的事先同意）</p>
          <div class="foot"></div>
        </div>
      </van-popup>
    </div>
  </router-layout>
</template>

<script>
import authMixin from "@/mixin/mixin";
export default {
  data() {
    return {
      id:'',
      show: false,
      checked: "",
      name: "",
      phone: "",
      message: "",
      phoneicon: require("@/assets/images/phone.svg")
    };
  },
  mixins: [authMixin],
  created(){
      this.id = this.$route.query.id
  },
  methods: {
    agree() {
      this.show = true;
    },
    subData(){
      this.$request({
        method:'post',
        url:'/cms/sign/' + this.id,
        data:{
          phone: this.phone,
          name: this.name,
          fid: this.id,
          description: this.message
        }
        }).then(res=>{
          console.log(res)
          this.$toast.clear();
          this.$toast.success({
            message: "报名成功,请等待审核",
            forbidClick: true,
            duration: 500
          });
          setTimeout(() => {
            this.$router.back();
          }, 700);
        })
    },
    Submit() {
      let reg = /^[1][3,4,5,7,8][0-9]{9}$/; //手机号正则验证
      let name = this.name;
      let phone = this.phone;
      let message = this.message;
      let checked = this.checked
      if (!name) {
        this.$toast({
          message: "请输入姓名",
          forbidClick: true
        });
      } else if (!phone) {
        this.$toast({
          message: "请输入手机号",
          forbidClick: true
        });
      }else if (!reg.test(phone)) {
        this.$toast({
          message: "请填写正确的手机号",
          forbidClick: true
        });
      }else if (!message) {
        this.$toast({
          message: "请填写留言内容",
          forbidClick: true
        });
      }else if(!checked){
        this.$toast({
          message: "请同意用户协议",
          forbidClick: true
        });
      }else{
          this.$toast.loading({
            loadingType: "spinner",
            forbidClick: true,
            duration: 0
          });
          this.subData()
      }
      /////
    }
  }
};
</script>

<style scoped lang="scss">
@import "../../../assets/iconfont/iconfont.css";
.page {
  background: url("~@/assets/images/logobg.png");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}
.inner {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  .title {
    padding-left: 0.25rem;
    padding-top: 1.7rem;
    font-size: 0.73rem;
    color: #fff;
    margin-bottom: 0.25rem;
  }
  .form {
    padding: 0.8rem 0.33rem;
    background: #fff;
    border-radius: 0.15rem;
    .input {
      .van-cell {
        background: transparent;
        border: 1px solid #eee;
        margin-bottom: 0.3rem;
        border-radius: 1.1rem;
      }
    }
    .textarea .van-cell {
      border-radius: 0.35rem;
      background: transparent;
      border: 1px solid #eee;
      margin-bottom: 0.3rem;
    }
    .submit {
      padding: 0;
      font-size: 0.42rem;
      width: 100%;
      border: none;
      outline: none;
      text-align: center;
      line-height: 1rem;
      height: 1rem;
      border-radius: 1rem;
      color: #fff;
      background: linear-gradient(90deg, #78adfc, #567cf3);
      margin-bottom: 0.45rem;
      margin-top: 0.15rem;
    }
    .ys {
      text-align: center;
      font-size: 0.34rem;
      color: #999;
    }
  }
}
.agreebox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.35rem;
  margin: 0.45rem 0;
  .van-radio__label {
    color: #888;
  }
  .agree {
    color: #f3b682;
    text-decoration: underline;
  }
}
.agreePop {
  overflow: hidden;
  height: 100vh;
  width: 100%;
  box-sizing: border-box;
  padding: 0.4rem 0.3rem 0.35rem 0.3rem;
  .tit {
    font-size: 0.5rem;
    color: #777;
    margin-bottom: 0.45rem;
  }
  h2 {
    margin: 0.3rem 0;
    font-weight: normal;
    font-size: 0.42rem;
  }
  p {
    font-size: 0.34rem;
    color: #777;
    line-height: 0.55rem;
  }
  .body {
    overflow-y: auto;
    height: calc(100vh - 1.6rem);
  }
  .foot {
    height: 0.6rem;
  }
}
</style>