import { isWX, storage, toWx } from '@/utils/utils'

export default {
    data() {
        return {

        }
    },
    created() {
        this.WxAuth()

    },
    methods: {
        auth() {
            window.location.href = 'http://cxzx.hzhope.com/leaseAuth.html?type=lease'
        },
        async WxAuth() {
            try {
                let code = this.$route.query.code
                let $storage = new storage()
                let token = $storage.get('token')
                if (token) {

                } else {
                    if (code) {
                        console.log('去获取koken')
                        await this.$authRequest({
                            method: 'get',
                            url: '/getWebToken',
                            params: {
                                code: code
                            }
                        }).then(res => {
                            console.log(res)
                            $storage.set('token', res.token.token)
                        })

                    } else {
                        this.auth()
                    }
                }
            } catch (err) {
                console.log(err)

            }
        }
    },
    beforeRouteEnter(to, from, next) {

        let $isWX = isWX()
        let $storage = new storage()
        let token = $storage.get('token')

        if ($isWX) {
            // 微信中打开
            next()

            if (token) {

                next()

            } else {

                next(vm => {
                    // vm.auth()
                })

            }
        } else {
            // 不是微信中打开
            toWx()

            next()
        }
        //

    }
}